import produce from 'immer';

import type {RoadPanel} from 'models/RoadPanel';

type Feat = RoadPanel['data']['features'][number];

const updateFeature =
  (updateSelected: (x: Feat, features: Feat[]) => void) => (state: RoadPanel, id: number) => {
    return produce(state, (draft) => {
      const {features} = draft.data;

      features.forEach((feat) => {
        if (feat.properties!.id === id) {
          updateSelected(feat, features);
        }
      });
    });
  };

const isConnectedLine =
  ({properties: item}) =>
  ({properties: p}) =>
    !!p!.wayId && !!p!.nodes && p!.nodes.includes(item!.id);

const isIgnored = ({properties: p}) => p!.excluded;
const isNotIgnored = ({properties: p}) => !p!.excluded;

export const roadReducers = {
  removeAlertRoadPoint: updateFeature((r) => delete r.properties!.alert),
  setAlertRoadPoint: updateFeature((r, list) => {
    list.forEach(({properties: p}) => delete p!.alert);
    r.properties!.alert = true;
  }),

  removeRoadPoint: updateFeature((r, list) => {
    r.properties!.excluded = true;
    delete r.properties!.alert;

    list
      //get all connect lines
      .filter(isConnectedLine(r))
      //set excluded for all connect lines
      .forEach(({properties: p}) => (p!.excluded = true));

    const lines = list.filter(({geometry: g}) => g.type === 'LineString');

    const excludedLines = lines.filter(isIgnored);
    const nonExcludedLines = lines.filter(isNotIgnored);
    const nonExcludedPoints = list.filter(
      ({geometry: g, properties: p}) => g.type === 'Point' && !p!.excluded,
    );

    const orphanPoints = nonExcludedPoints.filter(
      ({properties: p}) =>
        excludedLines.some(({properties: pL}) => pL!.nodes.includes(p!.id)) &&
        nonExcludedLines.every(({properties: pL}) => !pL!.nodes.includes(p!.id)),
    );

    orphanPoints.forEach(({properties: p}) => (p!.excluded = true));
  }),
  reAddRoadPoint: updateFeature((r, list) => {
    delete r.properties!.excluded;
    delete r.properties!.alert;

    list
      //get all connect lines
      .filter(isConnectedLine(r))
      //set excluded for all connect lines
      .forEach(({properties: p}) => delete p!.excluded);

    const lines = list.filter(({geometry: g}) => g.type === 'LineString');

    const excludedLines = lines.filter(isIgnored);
    const nonExcludedLines = lines.filter(isNotIgnored);

    const excludedPoints = list.filter(
      ({geometry: g, properties: p}) => g.type === 'Point' && p!.excluded,
    );

    const orphanPoints = excludedPoints.filter(
      ({properties: p}) =>
        nonExcludedLines.some(({properties: pL}) => pL!.nodes.includes(p!.id)) &&
        excludedLines.every(({properties: pL}) => !pL!.nodes.includes(p!.id)),
    );

    orphanPoints.forEach(({properties: p}) => delete p!.excluded);
  }),

  updateRoadGeoJSON: (state: RoadPanel, data: RoadPanel['data']) => {
    return produce(state, (draft) => {
      const excludedItens = state.data.features.filter(({properties: p}) => p!.excluded);
      const {notExcludedLine, notExcludedPoint} = getExludedFilters(excludedItens);

      draft.data = {
        ...data,
        features: [
          ...data.features
            // avoid add duplicated itens (points and lines)
            .filter(notExcludedLine)
            .filter(notExcludedPoint),

          ...excludedItens,
        ],
      };
    });
  },
};

const getExludedFilters = (excludedItens: RoadPanel['data']['features']) => {
  const excludedIdItens = excludedItens
    .map(({properties: p, geometry: g}) => (g.type === 'Point' ? p.id : p.wayId))
    .filter(Boolean);

  const notExcludedPoint = getFilterBy(excludedIdItens, 'Point');
  const notExcludedLine = getFilterBy(excludedIdItens, 'LineString');

  return {notExcludedLine, notExcludedPoint};
};

const getFilterBy = (list: string[], type: 'Point' | 'LineString') => {
  return ({properties: p, geometry: g}) =>
    g.type === type ? !list.includes(p[type === 'Point' ? 'id' : 'wayId']) : true;
};
