import {snakeCase} from 'lodash';

import {PanelStatus} from '../__generated__/globalTypes';

import type {Status} from 'models';

const beakynPurple = {
  50: '#efdbfd',
  100: '#e0b8fc',
  200: '#d195fb',
  300: '#c271fa',
  400: '#b34ef9',
  500: '#8007d4',
  600: '#6a05b0',
  700: '#55048d',
  800: '#3f0369',
  900: '#2a0246',
  A100: '#EA80FC',
  A200: '#E040FB',
  A400: '#D500F9',
  A700: '#AA00FF',
};

export {beakynPurple};

const PANEL_STATUS_COLOR_DICTIONARY = {
  [PanelStatus.ACTIVE]: '#5cb85c',
  [PanelStatus.INACTIVE]: '#d24444',
  [PanelStatus.UNAVAILABLE]: '#BDC3C7',
};

export const statusToColor = (status: Status | PanelStatus) => {
  // TODO: Fix it when refactor PanelForm
  const statusText = snakeCase(status).toUpperCase();

  const color = PANEL_STATUS_COLOR_DICTIONARY[statusText];

  return color || '#5cb85c';
};
