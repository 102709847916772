import {createAction} from 'redux-act';

import type {Image, Panel, RoadPanel} from 'models';

import type {InitialState} from './reducers';
import type {ICreateActivityRecord} from '../activityQueue';

export interface RemoveImagePayload {
  image: Image;
  panel: Panel;
}

export interface SavePanelPayload {
  updatedPanel: InitialState['panel'];
  currentPanel: InitialState['panel'];
}

export const actions = {
  setPanel: createAction<InitialState['panel']>('panelForm/PANEL_FETCH_SUCCEEDED'),
  setPanelInput: createAction<InitialState['panelInput']>('panelForm/SET_PANEL_INPUT'),
  cleanPanelForm: createAction('panelForm/CLEAN_PANEL_FORM'),
  setPanelNavigationIndex: createAction<InitialState['panelNavigationIndex']>(
    'panelForm/SET_PANEL_NAVIGATION_INDEX',
  ),

  loadPanel: createAction<InitialState['panel']['id']>('panelForm/PANEL_FORM_DATA_REQUESTED'),
  buildPanelNavigationIndex: createAction<string>('panelForm/BUILD_PANEL_NAVIGATION_INDEX'),

  createPanel: createAction<InitialState['panelInput']>('panelForm/CREATE_PANEL_REQUESTED'),
  updatePanel: createAction<InitialState['panelInput'] & {id: string}>(
    'panelForm/UPDATE_PANEL_REQUESTED',
  ),
  removePanel: createAction<String>('panelForm/REMOVE_PANEL'),
  createPanelActivity: createAction<ICreateActivityRecord>(
    'panelForm/CREATE_PANEL_ACTIVITY_REQUESTED',
  ),

  showPanelFormLoading: createAction('app/SHOW_PANEL_FORM_LOADING'),
  hidePanelFormLoading: createAction('app/HIDE_PANEL_FORM_LOADING'),
  toggleMapDialog: createAction('panelForm/TOGGLE_MAP_DIALOG'),

  openCurationRoad: createAction('panelForm/OPEN_CURATION_ROAD'),
  resetCurationRoad: createAction('panelForm/RESET_CURATION_ROAD'),
  saveCurationRoad: createAction('panelForm/SAVE_CURATION_ROAD'),

  closeCurationRoad: createAction('panelForm/CLOSE_CURATION_ROAD'),
  setRawRoad: createAction<RoadPanel>('panelForm/SET_CURATION_ROAD'),
  initCurationRoad: createAction<RoadPanel>('panelForm/INIT_CURATION_ROAD'),
  setCurationRoad: createAction<RoadPanel>('panelForm/SET_CURATION_ROAD'),
  updateRoadGeoJSON: createAction<RoadPanel['data']>('panelForm/UPDATE_ROAD_GEOJSON'),

  removeRoadPoint: createAction<number>('panelForm/REMOVE_ROAD_POINT'),
  reAddRoadPoint: createAction<number>('panelForm/RE_ADD_ROAD_POINT'),

  trySetAlertRoadPoint: createAction<number>('panelForm/TRY_SET_ALERT_ROAD_POINT'),
  tryRemoveAlertRoadPoint: createAction<number>('panelForm/TRY_REMOVE_ALERT_ROAD_POINT'),

  setAlertRoadPoint: createAction<number>('panelForm/SET_ALERT_ROAD_POINT'),
  removeAlertRoadPoint: createAction<number>('panelForm/REMOVE_ALERT_ROAD_POINT'),
};
